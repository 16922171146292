import React, { lazy, Suspense } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom'; 

import GlobalStyles from './globalStyles';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LoadingSpinner from './components/LoadingSpinner';
import "./App.css";
import "./fonts/Mukta-Bold.ttf";

// Composants lazy-loaded
const Hero = lazy(() => import('./components/Hero'));
const AboutMe = lazy(() => import('./components/AboutMe'));
const Works = lazy(() => import('./components/Works'));
const Skills = lazy(() => import('./components/Skills'));

function App() {
  return (
    <Router>
      <div className="font-mukta min-h-screen flex flex-col bg-primary-bg" id="top">
        <GlobalStyles />
        <Navbar />
        <div className="flex-grow pt-16"> {/* Réduit de pt-20 à pt-16 */}
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="/" element={
                <>
                  <section id="hero"><Hero /></section>
                  <section id="skills"><Skills /></section>
                  <section id="works"><Works /></section>
                  <section id="about"><AboutMe /></section>           
                </>
              } />
            </Routes>
          </Suspense>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
