import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaLinkedin, FaGithub, FaEnvelope, FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const scrollToSection = (sectionId) => {
    const section = document.querySelector(sectionId);
    if (section) {
      const navHeight = document.querySelector('nav').offsetHeight;
      const targetPosition = section.offsetTop - navHeight;
      
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
      });
      setIsOpen(false);
    }
  };

  return (
    <AnimatePresence>
      {visible && (
        <motion.nav
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          exit={{ y: -100 }}
          transition={{ duration: 0.3 }}
          className="w-full p-5 flex justify-between items-center bg-primary-bg/30 fixed top-0 z-[1000] backdrop-blur-[10px]"
        >
          <motion.a 
            href="/"
            onClick={(e) => {
              e.preventDefault();
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className="text-white text-2xl cursor-pointer"
            whileHover={{ scale: 1.2 }}
            transition={{ duration: 0.3 }}
          >
            Khalid Ouhmid
          </motion.a>

          {/* Navigation Links - Desktop */}
          <div className="flex sm:hidden">
            {['skills', 'works', 'about'].map((item) => (
              <a
                key={item}
                href={`#${item}`}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection(`#${item}`);
                }}
                className="text-white ml-5 hover:text-[#64ffda] transition-colors duration-300"
              >
                {item.charAt(0).toUpperCase() + item.slice(1)}
              </a>
            ))}
          </div>

          {/* Social Icons - Desktop */}
          <div className="flex sm:hidden">
            {[
              { icon: <FaLinkedin />, href: "https://www.linkedin.com/in/khalid-ouhmid-062830329/" },
              { icon: <FaGithub />, href: "https://github.com/khalidOuhmid" },
              { icon: <FaEnvelope />, href: "mailto:khalidouhmid.pro@gmail.com" }
            ].map((social, index) => (
              <a
                key={index}
                href={social.href}
                target="_blank"
                rel="noopener noreferrer"
                className="text-2xl text-white ml-4 hover:text-[#64ffda] transition-colors duration-300"
              >
                {social.icon}
              </a>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <button 
            onClick={() => setIsOpen(!isOpen)}
            className="text-white text-2xl cursor-pointer z-[1001] hidden sm:block"
          >
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>

          {/* Mobile Menu */}
          <AnimatePresence>
            {isOpen && (
              <>
                {/* Overlay */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="fixed inset-0 bg-black/70 z-[998] hidden sm:block"
                  onClick={() => setIsOpen(false)}
                />

                {/* Mobile Navigation */}
                <motion.div
                  initial={{ x: "100%" }}
                  animate={{ x: 0 }}
                  exit={{ x: "100%" }}
                  transition={{ type: "spring", stiffness: 100 }}
                  className="fixed top-0 right-0 h-screen w-3/4 bg-[#112240] p-20 z-[999] flex flex-col hidden sm:flex"
                >
                  {/* Mobile Nav Links */}
                  {['about', 'skills', 'works'].map((item) => (
                    <a
                      key={item}
                      href={`#${item}`}
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSection(`#${item}`);
                      }}
                      className="text-white text-xl my-5 hover:text-[#64ffda] transition-colors duration-300"
                    >
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </a>
                  ))}

                  {/* Mobile Social Icons */}
                  <div className="flex gap-5 mt-8">
                    {[
                      { icon: <FaLinkedin />, href: "https://www.linkedin.com/in/khalid-ouhmid-062830329/" },
                      { icon: <FaGithub />, href: "https://github.com/khalidOuhmid" },
                      { icon: <FaEnvelope />, href: "mailto:khalidouhmid.pro@gmail.com" }
                    ].map((social, index) => (
                      <a
                        key={index}
                        href={social.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-2xl text-white hover:text-[#64ffda] transition-colors duration-300"
                      >
                        {social.icon}
                      </a>
                    ))}
                  </div>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </motion.nav>
      )}
    </AnimatePresence>
  );
};

export default Navbar;
